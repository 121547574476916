import DpFormMixin from 'src/core/components/DpForm/DpFormMixin.js'
import DpForm from 'src/core/components/DpForm/DpForm2.vue'
import AcessorioModel from 'src/model/veiculo/AcessorioModel.js'
import GrupoAcessorioModel from 'src/model/veiculo/GrupoAcessorioModel.js'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import Upload from 'src/components/Upload.vue'
import IcarrosAcessorioModel from 'src/model/veiculo/IcarrosAcessorioModel'
import WebmotorsAcessorioModel from 'src/model/veiculo/WebmotorsAcessorioModel'
import UsadosbrAcessorioModel from 'src/model/veiculo/UsadosbrAcessorioModel'
import OlxAcessorioModel from 'src/model/veiculo/OlxAcessorioModel'
import MobiautoAcessorioModel from 'src/model/veiculo/MobiautoAcessorioModel'

export default {
  name: 'CategoriaForm',
  components: { DpForm, Upload },
  mixins: [DpFormMixin, NotificacaoMixin],
  data () {
    return {
      codigos_icarros: [],
      codigos_webmotors: [],
      codigos_usadosbr: [],
      codigos_olx: [],
      codigos_mobiauto: [],
      grupos: [],
      modelPadrao: new AcessorioModel(),
      modelGrupoAcessorio: new GrupoAcessorioModel(),
      modelIcarrosAcessorio: new IcarrosAcessorioModel(),
      modelWebmotorsAcessorio: new WebmotorsAcessorioModel(),
      modelUsadosbrAcessorio: new UsadosbrAcessorioModel(),
      modelOlxAcessorio: new OlxAcessorioModel(),
      modelMobiautoAcessorio: new MobiautoAcessorioModel()
    }
  },
  mounted () {
    this.modelGrupoAcessorio.getListagem().then((response) => {
      this.grupos = response.dados ?? []
    }).catch((error) => {
      this.notificacao('erro', error.msg)
    })
    this.modelIcarrosAcessorio.getListagem().then((response) => {
      this.codigos_icarros = response.dados ?? []
    }).catch((error) => {
      this.notificacao('erro', error.msg)
    })
    this.modelWebmotorsAcessorio.getListagem().then((response) => {
      this.codigos_webmotors = response.dados ?? []
    }).catch((error) => {
      this.notificacao('erro', error.msg)
    })
    this.modelUsadosbrAcessorio.getListagem().then((response) => {
      this.codigos_usadosbr = response.dados ?? []
    }).catch((error) => {
      this.notificacao('erro', error.msg)
    })
    this.modelOlxAcessorio.getListagem().then((response) => {
      this.codigos_olx = response.dados ?? []
    }).catch((error) => {
      this.notificacao('erro', error.msg)
    })
    this.modelMobiautoAcessorio.getListagem().then((response) => {
      this.codigos_mobiauto = response.dados ?? []
    }).catch((error) => {
      this.notificacao('erro', error.msg)
    })
  },
  methods: {
    setarImagem (e) {
      this.modelPadrao.form.foto = e.arquivo
      this.modelPadrao.form.foto_url_completa = e.arquivo.arquivo_url_completa
    }
  }
}
